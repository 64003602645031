import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './authGuardService/auth-guard.service';
const routes: Routes = [
  { path:'', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path:'eplimo-app', loadChildren:() => import('./modules/test/test.module').then( m => m.TestModule) },

 { path:'book', loadChildren:() => import('./modules/book/book.module').then( m => m.BookModule) },

 { path:'coaching', loadChildren:() => import('./modules/lifestyle_coaching/lifestyle_coaching.module').then( m => m.LifestyleCoachingModule) },

 { path:'promotion', loadChildren:() => import('./modules/promotion/promotion.module').then( m => m.PromotionModule) },

 { path:'eplimo', loadChildren:() => import('./modules/eplimo/eplimo.module').then( m => m.EplimoModule) },

 { path:'viecommerce', loadChildren:() => import('./modules/viecommerce/viecommerce.module').then( m => m.ViecommerceModule) },
 { path:'enquiry', loadChildren:() => import('./modules/enquiry/enquiry.module').then( m => m.EnquiryModule) },


 { path:'career', loadChildren:() => import('./modules/career/career.module').then( m => m.CareerModule) },



  // { path:'auth', loadChildren:() => import('./modules/auth/auth.module').then( m => m.AuthModule) },
  { path: 'user' , canActivate: [AuthGuardService], loadChildren: () => import('./modules/user/user.module').then( m => m.UserModule) },
  { path: 'products', loadChildren: () => import('./modules/products/products.module').then( m => m.ProductsModule) },
  { path: 'cart', canActivate: [AuthGuardService], loadChildren: () => import('./modules/cart/cart.module').then( m => m.CartModule) },
  { path:'general', loadChildren:() => import('./modules/general/general.module').then( m => m.GeneralModule) },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
