import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Interceptor } from './interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AlertsComponent } from './sharedComponents/modals/alerts/alerts.component';
// import { VideoSrcPipe } from './pipes/video-src.pipe';
// import { ProfileFileUploadComponent } from './sharedComponents/modals/profile-file-upload/profile-file-upload.component';
// import { ImageCropperModule } from 'ngx-image-cropper';
// import { LoginComponent } from './sharedComponents/modals/login/login.component';
// AlertsComponent,
//     ProfileFileUploadComponent,
//     LoginComponent
@NgModule({
  declarations: [
    AppComponent
    // AlertsComponent,
    // ResetPasswordComponent,
    // VideoSrcPipe
    // ProfileFileUploadComponent,
    // LoginComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    // ImageCropperModule

  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: Interceptor,
    multi: true
  }, ],
  bootstrap: [AppComponent],
  exports: [
    // AlertsComponent,
    NgbModule,

  ],
  entryComponents: [
    // AlertsComponent,
  ]
})
export class AppModule { }
