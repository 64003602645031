import { Component, Inject, PLATFORM_ID, Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Params, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { CommonService } from './commonService/common.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
@Injectable()
export class AppComponent {
  title = 'vieroots';
  constructor(private activatedRoute: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: any, private router: Router,private metaService: Meta) {
    this.storeWAID()
    this.addTag();
  }
  // storeWAID() {
  //   this.activatedRoute.queryParams.subscribe((params: Params) => {
  //       localStorage.setItem('waid', params.waid);
  //     }


  addTag() {      
    this.metaService.addTag({name: 'description', property: 'og:description', content: 'First ever epigenetic lifestyle modification company' });
    this.metaService.addTag({name: 'title',  property: 'og:title', content: 'Vieroots' });
    this.metaService.addTag({ name: 'url', property: 'og:url', content: 'https://vieroots.com' });
    this.metaService.addTag({ name: 'image', property: 'og:image', content: 'assets/images/vierootslogo-2.png' }); 

     this.metaService.addTag({ property: 'og:image:width', content: '200px' });
    this.metaService.addTag({ property: 'og:image:height', content: '100px' }); 

    this.metaService.addTag({ property: 'og:type', content: 'website' });
   
  }



  storeWAID() {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {

          const userData = localStorage.getItem('user_data');
          let user = { wa_url: null, id: null };
          if (userData)
            user = JSON.parse(userData);
          this.activatedRoute.queryParams.subscribe((params: Params) => {

            if (params.waid && (!userData || !user.wa_url)) {
              localStorage.setItem('waid', params.waid);
            }
            else {
              const data = localStorage.getItem('waid');

              if (data && (!userData || !user.wa_url)) {
                const queryParams: Params = { waid: data };
                this.router.navigate(
                  [],
                  {
                    relativeTo: this.activatedRoute,
                    queryParams,
                    replaceUrl: true,
                    queryParamsHandling: 'merge', // remove to replace all query params by provided
                  });
              }
              else if (userData && user.wa_url && user.wa_url.trim() !== '') {
                const queryParams: Params = { waid: user.id };
                localStorage.setItem('waid', user.id);
                this.router.navigate(
                  [],
                  {
                    relativeTo: this.activatedRoute,
                    queryParams,
                    replaceUrl: true,
                    queryParamsHandling: 'merge', // remove to replace all query params by provided
                  });
              }

            }
          });
        }
      })

    }

  }
}
