import { PLATFORM_ID, Injectable, Inject, } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { CommonService } from './commonService/common.service';
import 'rxjs/add/operator/do';
import { environment } from 'src/environments/environment';
import { EMPTY } from 'rxjs';

@Injectable()
export class Interceptor implements HttpInterceptor {
  noAuthUrls = [
    '/apiUrl/test/all',
    '/apiUrl/product-categories',
    '/apiUrl/home/list_banner',
    '/apiUrl/product-banners',
    '/apiUrl/about-us'
  ]
  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private commonService: CommonService
  ) { }

  getToken() {
    if (isPlatformBrowser(this.platformId)){
    const user = localStorage.getItem('user_data')
    if (user !== null) {
      return JSON.parse(user).access_token
    }
  }
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.getToken();
    if (isPlatformBrowser(this.platformId)){
    request = request.clone({
      setHeaders: {
        Accept: 'application/json',
      },
    });
  }else {
    request = request.clone({
      setHeaders: {
        Accept: 'application/json'
      },
      url:environment.api+request.url
    });
  }
    if (token !== undefined) request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          if (isPlatformBrowser(this.platformId)){
          // this.commonService.updataUserData({});
          // this.commonService.updateCartCount(0);
          // this.commonService.updateTotalCartAmount(0);
          // this.commonService.updateCartDeliveryCharge(0);
          localStorage.removeItem('user_data');
          this.commonService.toaster({ message: 'Session Expired!' })
          // this.router.navigate(['cart/checkout']);
          setTimeout(()=>{
            window.location.reload();
          },3000)
          // redirect to the login route
          // or show a modal
        }
        }
      }
    });
    }
  }
