import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
// import { AuthService } from '../service/auth.service';
import { CommonService } from '../commonService/common.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private commonService: CommonService, private activatedRoute: ActivatedRoute,
    private myRoute: Router, @Inject(PLATFORM_ID) private platformId: any) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.commonService.isLoggednIn()) {
      return true;
    } else {
      if (isPlatformBrowser(this.platformId)) {
        sessionStorage.setItem('redirect_to', window.location.pathname)
      }
      if (next.queryParams[`waid`]) {
        const queryParams: Params = { waid: next.queryParams[`waid`] };
        this.myRoute.navigate(
          ['home'],
          {
            relativeTo: this.activatedRoute,
            queryParams,
            replaceUrl: true,
            queryParamsHandling: 'merge', // remove to replace all query params by provided
          });
      }
      else {
        this.myRoute.navigate([`home`]);
      }
      return false;
    }
  }
}
