import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  // config: any;
  // message = 'Snack Bar opened.';
  // actionButtonLabel = 'Retry';
  // action = true;
  // setAutoHide = true;
  // autoHide = 2000;
  // horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  // verticalPosition: MatSnackBarVerticalPosition = 'top';
  apiUrl = environment.apiUrl;
  constructor(
    @Inject(PLATFORM_ID) private platformId: any, private http: HttpClient
  ) {
  }

  private _userData = new BehaviorSubject<object>({})
  public userData = this._userData.asObservable();


  private _otpData = new BehaviorSubject<object>({})
  public otpData = this._otpData.asObservable();

  private _cartCount = new BehaviorSubject<number>(0)
  public cartCount = this._cartCount.asObservable();

  private _cartCountNutrigenom = new BehaviorSubject<number>(0)
  public cartCountNutrigenom = this._cartCountNutrigenom.asObservable();

  private _checkLocalCartNutrigenom = new BehaviorSubject<object>({})
  public checkLocalCartNutrigenom = this._checkLocalCartNutrigenom.asObservable();

  private _bundleNutrigenom = new BehaviorSubject<any>({})
  public bundleNutrigenom = this._bundleNutrigenom.asObservable();



  private _snakeBar = new BehaviorSubject<object>({})
  public snakeBar = this._snakeBar.asObservable();

  private _cartSnakeBar = new BehaviorSubject<object>({})
  public cartSnakeBar = this._cartSnakeBar.asObservable();

  private _cartAmountNutrigenom = new BehaviorSubject<object>({})
  public cartAmountNutrigenom = this._cartAmountNutrigenom.asObservable();

  private _cartAmount = new BehaviorSubject<object>({})
  public cartAmount = this._cartAmount.asObservable();

  private _privilegePoint = new BehaviorSubject<object>({})
  public privilegePoint = this._privilegePoint.asObservable();


  private _cartDeliveryCharge = new BehaviorSubject<object>({})
  public cartDeliveryCharge = this._cartDeliveryCharge.asObservable();

  // toaster(message='oioo', actionButtonLabel='hjgjg') {
  //   this.config = new MatSnackBarConfig();
  //   this.config.verticalPosition = this.verticalPosition;
  //   this.config.horizontalPosition = this.horizontalPosition;
  //   this.config.duration = this.setAutoHide ? this.autoHide : 0;
  //   this.snackBar.open(message, this.action ? actionButtonLabel ? actionButtonLabel : undefined : undefined, this.config);
  // }
  public categoriesList: BehaviorSubject<object> = new BehaviorSubject([])
  updataUserData(data) {
    this._userData.next(data)
  }
  updateCartCount(data) {
    if (isPlatformBrowser(this.platformId))
    this._cartCount.next(data)
    // localStorage.setItem('cartData',JSON.parse(data));
  }
  updateTotalCartAmount(amount) {
    if (isPlatformBrowser(this.platformId))
    this._cartAmount.next(amount)
    // localStorage.setItem('cartData',JSON.parse(data));
  }

  updatePrivilegePoint(point) {
    if (isPlatformBrowser(this.platformId))
    this._privilegePoint.next(point)  
  }

  updateCartAmountNutrigenom(amount) {
    if (isPlatformBrowser(this.platformId))
    this._cartAmountNutrigenom.next(amount)
    // localStorage.setItem('cartData',JSON.parse(data));
  }


  updateCartDeliveryCharge(charge) {
    if (isPlatformBrowser(this.platformId))
    this._cartDeliveryCharge.next(charge)
    // localStorage.setItem('cartData',JSON.parse(data));
  }
  toaster(data) {
    if (isPlatformBrowser(this.platformId)){
    this._snakeBar.next(data)
    }
  }
  cartToaster(data) {
    if (isPlatformBrowser(this.platformId)){
    this._cartSnakeBar.next(data)
    }
  }
  categories(data): void {
    this.categoriesList.next(data)
  }
  public getGategories(): Observable<object> {
    return this.http.get<object>(this.apiUrl + 'product-categories')
      .pipe(catchError(this.handleError))
  }
  public addToCart(item): Observable<object> {
    return this.http.post<object>(this.apiUrl + 'product/add-to-cart', item)
      .pipe(catchError(this.handleError))
  }
  public getFooterData(): Observable<object> {
    return this.http.get<object>(this.apiUrl + 'footer')
      .pipe(catchError(this.handleError))
  }

  public walletPoint(): Observable<object> {
    return this.http.get<object>(this.apiUrl + 'wallet_point')
      .pipe(catchError(this.handleError))
  }


  checkLocalCartExistsAndUpdateToDb() {
    // this.clearForm()
    const localCart = this.checkLocalCart();
    if (localCart != null) {
      const LC = JSON.parse(localCart)
      console.log(LC)
      LC.forEach(item => {
        this.addToCart({ product_id: 15 })
          .subscribe((res: any) => {
            console.log(res)
            this.RemoveFromLocalCart(LC, 15);
          },
            (err: any) => {
              console.log(err)
            }
          )
      });
    }
  }
  RemoveFromLocalCart(localCart, id) {
    console.log(localCart, id)
    const cart = { tempLocalCart: localCart }
    console.log(cart)
    localCart.forEach((item, index: number) => {
      if (item.id === id) {
        cart.tempLocalCart.slice(index, 1)
      }
      console.log(cart)

    });
    console.log(cart)
  }

  // Error handling;
  handleError(error) {
    let errorMessage = '';
    if (isPlatformBrowser(this.platformId)){
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
  }

  updateOtpData(data) {
    this._otpData.next(data)
  }
  getCartCount() {
    this.checkLocalCart();
  }
  checkLocalCart() {
    if (isPlatformBrowser(this.platformId)){
      const localCart = localStorage.getItem('cart')
      const tempCart = []
      if (localCart != null) {
        const _localCart = JSON.parse(localCart);
        _localCart.forEach(element => {
          if (element.cart_quantity != null) {
            tempCart.push(element)
          }
        });
        this._cartCount.next(tempCart.length)
        return JSON.stringify(tempCart);
      } else {
        return null;
      }
    }
  }



  updateCartNutrigenom() {
    this.checkLocalCartNutrigenomDetails();
    // localStorage.setItem('cartData',JSON.parse(data));
  }



  updateLocalCartNutrigenom(item){

    const localCart = JSON.parse(localStorage.getItem('cart_nutrigenom'));
    let newArray = localCart.filter((element, i) => element.id !== item.id);
    localStorage.setItem('cart_nutrigenom', JSON.stringify(newArray));
  }


  updateBundle(bundle = null) {
   
    if(bundle == null){
      this._bundleNutrigenom.subscribe(v =>{         
        if(Array.isArray(v) == true)
         bundle = v;
         else 
          return null;
      })

      if(bundle == null)
        return null;
    }

    if(bundle.length == 0)
      return  null;    

    const localCart = localStorage.getItem('cart_nutrigenom')
    const bundle_disable = localStorage.getItem('bundle_disable')
    let bundleCart : boolean;
    let _localCart : any
    let _bundle_disable : boolean  = false;
    const tempData = [];
    
    
      if (localCart != null) 
        _localCart = JSON.parse(localCart); 

//alert(bundle_disable)
      if (bundle_disable != null) 
        _bundle_disable = JSON.parse(bundle_disable);  

        bundle.forEach(data => {
              bundleCart = false;
           
         if (_localCart != null && _localCart.length > 0) { //alert(_localCart.length)
              _localCart.forEach(element => {
              
              if(data.id == element.id){  
                  if(data.purchase == true)
                      this.updateLocalCartNutrigenom(element)
                  else
                      bundleCart = true;
                      
                  return;
                }                              
                });
            }

            if(bundleCart)
              data.cart = true;
            else
              data.cart = false;

            if(_bundle_disable == true)
              data.purchasable = false;
            else 
              data.purchasable = true;

            tempData.push(data)

           // alert(data.purchase)

        });

    this._bundleNutrigenom.next(tempData)
   
}


updateBundleCart(){
  
    this.updateBundle(this._bundleNutrigenom)

}



  getCheckLocalCartNutrigenom() {
    this.checkLocalCartNutrigenomDetails();
  }

  checkLocalCartNutrigenomDetails() {
    if (isPlatformBrowser(this.platformId)){
      const localCart = localStorage.getItem('cart_nutrigenom')
      const tempCart = []
      let amount : any;
      let bundleCart : boolean;
      amount = 0;
      if (localCart != null) {
        const _localCart = JSON.parse(localCart);  
       
         _localCart.forEach(element => { 
                tempCart.push(element)
                amount +=  parseInt(element.mrp)                          
            });


        console.log(this._bundleNutrigenom)

        this._cartCountNutrigenom.next(tempCart.length)
        this._cartAmountNutrigenom.next(amount)
        this._checkLocalCartNutrigenom.next(tempCart)

        if(_localCart.length <= 0)
          localStorage.setItem('bundle_disable', JSON.stringify(false));

       this.updateBundle(null)
        
        return JSON.stringify(tempCart);
      } else {
        return null;
      }
    }
  }



  getUserData() {
    if (isPlatformBrowser(this.platformId)){
    const user = localStorage.getItem('user_data')
    if (user != null && user !== undefined) {
      if (JSON.parse(user).access_token != null) {
        this.updataUserData(JSON.parse(user));
        return user
      } else {
        return null
      }
    } else { return null }
  }
  }

  isLoggednIn() {
    if (this.getUserData() != null) {
      return true;
    } else {
      return false;
    }
  }



}
